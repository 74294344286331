/* Global font settings */
* {
  font-family: 'Helvetica', sans-serif;
}

/* General container styles */
.squestions-container {
    font-family: 'Helvetica', sans-serif;
    padding: 20px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }
  
  /* Title styles */
  .squestions-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Audio sections */
  .survey-audios {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .audio-column {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .audio-wrapper {
    margin-bottom: 10px;
  }
  
  /* Test audio section */
  .test-audio-section {
    margin-top: 30px;
    text-align: center;
  }
  
  /* Button section */
  .button-section {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .option-button {
    padding: 20px 40px;
    width: 300px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    transition: border 0.2s, transform 0.2s;
  }
  
  .option-button.option-a {
    background-color: #a099d4;
    color: #fff;
  }
  
  .option-button.option-b {
    background-color: #f996ad;
    color: #fff;
  }
  
  .option-button.selected {
    border: 3px solid #000;
    transform: scale(1.05);
  }
  
  /* Selected option display */
  .selected-option {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  @media screen and (max-width: 768px) {
    .survey-audios {
      flex-direction: column;
    }

    .audio-column {
      width: 100%;
    }

    .option-button {
      width: 90%;
      max-width: 400px;
      height: auto;
      padding: 15px 20px;
    }

    .test-audio-section {
      margin-top: 20px;
    }

    .squestions-container {
      padding: 10px;
    }
    .button-section {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      
    }
  }
