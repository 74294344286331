/* Global font settings */
/* * {
  font-family: sans-serif;
} */

/* General container styles */
.survey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.survey-subtitle {
  text-align: center;
  color: #000000;
  margin: 10px;
  margin-bottom: 0px;
  font-size: 2.5rem;
}

.survey-title {
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
  font-size: 5.5rem;
}

.survey-start {
  text-align: center;
  margin-top: 20px;
}

.survey-input {
  padding: 10px;
  font-size: 16px;
  margin: 10px;
}

.survey-start-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.survey-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.survey-previous-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.survey-icon {
  margin-right: 8px;
}

.survey-question-number {
  font-size: 18px;
  font-weight: bold;
}

.survey-placeholder {
  width: 50px;
}

.survey-results {
  height: 100%;
  text-align: center;
}

.survey-results ul {
  list-style-type: none;
  padding: 0;
}

.survey-results li {
  margin: 10px 0;
}

.result-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  border: 2px solid transparent;
}

.result-details.expanded {
  border-color: black;
  border-radius: 10px;
  padding: 10px;
}

.survey-restart-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .survey-title {
      text-align: center;
      font-size: 2.8rem;
  }

  .survey-subtitle {
      font-size: 1.5rem;
  }
}

@media screen and (max-width: 370px) {
    .survey-input {
        padding: 10px;
        font-size: 16px;
        margin: 10px;
        width: 100%;
      }
  }